import React from 'react';
import eachWeekOfInterval from 'date-fns/eachWeekOfInterval';
import getDay from 'date-fns/getDay';
import { format, getYear } from 'date-fns';
import addDays from 'date-fns/addDays';
import isPast from 'date-fns/isPast';
import addYears from 'date-fns/addYears';
import { ru } from 'date-fns/locale';
import { TimelineContainer, Week } from './timeline.css';
import { Tooltip } from '../tooltip/Tooltip';

const YEARS_I_THINK_I_WILL_LIVE = 65;

const formatDay = time =>
  format(time, 'd MMMM', {
    locale: ru,
  });

const Timeline = props => {
  const weeks = Array.from({ length: YEARS_I_THINK_I_WILL_LIVE })
    .map((_, yearNum) => {
      const startOfYear = new Date(1999 + yearNum, 1, 25);
      return eachWeekOfInterval(
        {
          start: startOfYear,
          end: addYears(startOfYear, 1),
        },
        { weekStartsOn: getDay(startOfYear) }
      ).slice(0, -1);
    })
    .flat();
  return (
    <TimelineContainer>
      {weeks.map((week, weekNum) => {
        const firstDayOfWeek = week;
        const lastDayOfWeek = addDays(week, 7);
        const tooltipContent = `Неделя №${(weekNum % 52) + 1} с ${formatDay(
          firstDayOfWeek
        )} по ${formatDay(lastDayOfWeek)} ${getYear(lastDayOfWeek)} года`;

        return (
          <Tooltip content={tooltipContent}>
            <Week passed={isPast(lastDayOfWeek)} />
          </Tooltip>
        );
      })}
    </TimelineContainer>
  );
};

export default Timeline;
