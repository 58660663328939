import React from 'react';

import Layout from 'components/layout';
import Timeline from 'components/timeline';

const Page = ({ data }) => {
  return (
    <Layout>
        <p>Тут отражено 65 лет.</p>
        <p>В каждой строке 52 недели.</p>
        <p>Прошедшие с моего рожения недели закрашиваются автоматически.</p>
      <Timeline />
    </Layout>
  );
};

export default Page;
